<template>
  <v-list-item :href="data.url">
    <v-list-item-content>
      <v-list-item-title>
        {{ data.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ data.url }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "Item",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
