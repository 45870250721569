<template>
  <v-card color="background" flat>
    <v-container>
      <v-card flat>
        <v-card-title>
          Services
          <v-spacer />
          <v-btn
            color="cyan"
            dark
            depressed
            rounded
            @click="filter.status = !filter.status"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card v-if="filter.status">
            <v-card-text>
              <v-text-field
                v-model="filter.content"
                clearable
                dense
                label="Search"
              />
            </v-card-text>
          </v-card>
          <v-card v-if="!loaded" flat>
            <v-card-text>
              <v-progress-circular color="primary" indeterminate />
            </v-card-text>
          </v-card>
          <v-list v-else>
            <empty-item v-if="loaded && !services.length" />
            <div v-for="(data, name) in exportData" :key="name">
              <directory v-if="Array.isArray(data)" :name="name" :data="data" />
              <item v-else :data="data" />
            </div>
          </v-list>
        </v-card-text>
      </v-card>
    </v-container>
  </v-card>
</template>

<script>
import EmptyItem from "../components/ServiceEmptyItem.vue";
import Directory from "../components/ServiceDirectory.vue";
import Item from "../components/ServiceItem.vue";

export default {
  name: "Services",
  components: { EmptyItem, Directory, Item },
  data: () => ({
    loaded: false,
    filter: {
      status: false,
      content: null,
    },
    services: [],
  }),
  computed: {
    exportData() {
      if (this.filter.status) {
        return this.services.filter(
          (i) =>
            i.title.includes(this.filter.content) ||
            i.url.includes(this.filter.content)
        );
      } else {
        return this.services.reduce(function (result, item) {
          if (item.directory === "") {
            result[item.title] = item;
          } else {
            if (result[item.directory] instanceof Array) {
              result[item.directory].push(item);
            } else {
              result[item.directory] = [item];
            }
          }
          return result;
        }, {});
      }
    },
  },
  methods: {
    async load() {
      this.loaded = false;
      const response = await this.$appClient.get("/services");
      if (response.status === 200) this.services = response.data;
      this.loaded = true;
    },
  },
  created() {
    this.load();
  },
};
</script>
