<template>
  <v-list-group no-action>
    <template #activator>
      <v-avatar>
        <v-icon>mdi-folder-outline</v-icon>
      </v-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <v-list-item
      v-for="service in data"
      :key="service.id"
      :href="service.url"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{ service.title }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ service.url }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</template>

<script>
export default {
  name: "Directory",
  props: {
    name: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>
